body {
  background-color: #72b6f9;
  /* color: purple; */
  text-align: center;
}

/* global settings */
.navbar {
  padding: 2vw;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 2vh;
  position:fixed;
  width: 100vw;
  background: rgba(114, 182, 249,.7)
}

.summerToggle {
  align-self: flex-start;
}

.summerToggle a {
  text-decoration: none;
}

footer {
  margin-top: 2em;
}

footer a {
  margin: 5px;
}

footer .codexmon {
  background-color: black;
  border-radius: 100%;
}

.btn {
  background: rgb(220,368,78);
  border: none;
  margin: 2vw;
  padding: 1vw 2vw;
  border-radius: 10px;
  font-weight: bold;
  text-decoration: none;
}

.btn:hover {
  background: rgba(220,368,78,0.5);
  color: blue;
}

.btn.active {
  background: blue;
  color: white;
}

h3 {
  margin-bottom: 0;
}

p {
  margin-top: 5px;
}
/* netlify deployment shows in console an error about input type of password not in a form tag */
@font-face {
  font-family: 'password';
  font-style: normal;
  font-weight: 400;
  src: url("./Assets/Font/password.ttf");
}

.password {
  font-family: 'password';
  width: 100px;
  height: 16px;
}
/* current time display settings */
.current-time-section {
    display: flex;
    justify-content: center;
    padding-top: 40vw;
}
@media screen and (min-width: 375px) {
  .current-time-section {
    padding-top: 25vw;
  }
}

.current-time-section img {
  width: 80px;
  height: 70px;
}

.current-time-section h2 {
  font-size: xx-large;
}

/* stopwatch settings */
.stopwatches-section {
  display:flex;
  flex-direction: column-reverse;
}
.stopwatches-wrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.timer-wrapper {
  display: flex;
  justify-content: center;
  margin: 2vh 2em 10vh;
}
.timer {
  width: 100%;
}
.stopwatch-field{
  width: 30%;
  text-align: center;
  margin-top: 10px;
}
.item {
  width:60%;
}
.timer .btn {
  margin: 5px 0;
}
img.disabled {
  opacity: 60%;
}
.btn.disabled {
  background-color: transparent;
  outline: 1px solid blue;
}
/* .btn.disabled {
  background-color:rgba(220, 368, 78, .7)
} */
.value {
  font-size: 40px;
}
.text-bold {
  font-weight: bold;
}

/* all alarms section */
.past-alarms {
  color: gray;
  display: none;
}
.past-alarms .alarm-item{
  border: 1px solid gray;
}
.next-alarm {
  color: blue;
}
.next-alarm .alarm-item {
  border: 1px solid blue;
}
.upcoming-alarms {
  color: rgb(220, 368, 78);
}
.upcoming-alarms .alarm-item {
  border: 1px solid rgb(220, 368, 78);
}
.alarm-item {
  border-radius: 10px;
  margin: 5px;
  padding: 0 5px;
  font-weight: bold;
}
@media screen and (min-width: 768px) {
  .current-time-section {
    padding-top: 20vw;
  }
  .stopwatches-section {
    display:flex;
    flex-direction: column;
  }
  .stopwatches-wrapper {
    flex-direction: row;
  }
  .past-alarms {
    display: unset;
  }
  .all-alarms {
    display: flex;
    justify-content: space-between;
  }
  .all-alarms>div {
    width: 30%;
  }
}

/* daily prompt section */
.pic-prompt-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.prompt-wrapper {
  width: 270px;
  height: 200px;
  border: 1px solid blue;
  margin: 1em;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1em;
}

.pic-prompt {
  height: 220px;
}


.pic-only {
  height: 100%;
}

.prompt-wrapper p {
  font-size: 2em;
  font-weight: 500;
  font-style: italic;
  margin: 0;
}

/* english prompts section */
.english-prompts-wrapper {
  border: 1px solid blue;
  margin: 1em;
  padding: 1em;
  border-radius: 10px;
}

@media screen and (min-width: 900px) {
  .english-prompts-wrapper {
    width: 60vw;
    margin: auto;
  }
}

/* chinese prompts section */
.chinese-section h1 {
  display: flex;
  flex-direction: column;
}
.chinese-section h1 .romanization {
  font-weight: normal;
  font-size: 16px;
}

.chinese-prompts-wrapper {
  border: 1px solid black;
  margin: 10px;
  padding: 10px 10px 20px;
  border-radius: 10px;
}

.chinese-characters-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.chinese-characters {
  display: flex;
  flex-direction: column;
  font-size: 5em;
  font-family: 'Ma Shan Zheng', cursive;
  border: 1px solid black;
  border-radius: 10px;
  margin: 5px;
  padding: 5px;
}

.chinese-characters .chinese-translation {
  font-family: 'Arial', sans-serif;
  font-weight: bold;
  font-size: 24px;
}

.chinese-characters .romanization {
  font-family: 'Arial', sans-serif;
  font-size: 16px;
}

/* math vocabulary section */
.math-section h1 {
  display: flex;
  flex-direction: column;
}
.math-section h1 .romanization {
  font-weight: normal;
  font-size: 16px;
}

/* school schedules section */
.display-all, .school-header .hide {
  display: none;
}

.school-header1 .active, .school-header2 .active, .school-header3 .active, .school-header4 .active{
  display: contents;
}

.btn-school {
  display: flex;
  justify-content: center;
  align-items: center;
  background:rgba(220, 368, 78, .6);
}
.collapse, .open {
  width: 16px;
  height: 16px;
  margin-left: 5px;
}

.open {
  transform: rotate(90deg);
}

@media screen and (min-width: 768px) {
  .display-all {
      display: contents;
    }
  .holiday, .minimum-day, .display-all {
    display: flex; 
    justify-content: center;
    align-items: center;
  }
  .holiday p, .minimum-day p, .display-all p {
    margin: 0;
  }
  .school-schedules {
      display: flex;
      justify-content: center;
    }
  .btn-school {
    margin: 2vw 5px 10px;
    padding: 0;
  }
  .school-header,
  .school-schedules > div {
    width: 25vw;
  }
  .collapse,
  .open {
    display: none;
  }
}

/* lottery tickets section */
.learning-lottery-section h2 {
  margin-bottom: 10px;
}

.form-group {
  width: 90%;
  outline: 1px solid blue;
  border-radius: 20px;
  padding-top: 10px;
  margin: auto;
  margin-bottom: 20px;
}

.form-control {
  display:flex;
  flex-direction: column;
  margin: 10px 5px;
}

.form-control label {
  font-weight: bold;
}

.form-control input {
  text-align: center;
  margin-bottom: 1em;
}

.who-options {
  display: flex;
  justify-content: space-around;
}

.form-submit {
  padding: 10px;
  margin-bottom: 2em;
  width: 80%;
}

.ticket-display {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ticket-holder {
  background-image: url("./Assets/Images/ticket.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 80%;
  margin: 10px 0;
}

.buck-holder {
  background-image: url("./Assets/Images/dollar.jpg");
}

hr {
  margin: 3em 0;
}

.raffle-section img {
  width: 100%;
}

.prizes-section, .dice-prize {
  display: flex;
}

.prizes-section {
  flex-direction: column;
}

.dice-prize {
  flex-direction: row;
  align-items: center;
  text-align: left;
}
.dice-prize img, .dice-roll img {
  width: 30%;
  border-radius: 10px;
  margin: 5px;
}
.dice-prize p {
  margin: 5px;
  font-weight: bold;
  width: 80%
}

.dice-roll-section .ticket-display, .dice-roll {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

.dice-roll-section .ticket-display .ticket-holder {
  margin: auto;
}

.dice-roll-section .dice-roll img, .dice-roll-section .dice-roll p{
  width: 90%;
}
.dice-roll-section .dice-roll p {
 margin: auto;
}

@media screen and (min-width: 768px) {
  .form-group {
    width: 40%;
    margin-bottom: 3em;
  }
  .form-control {
    align-items: center;
  }
  .ticket-display {
    flex-direction: row;
    justify-content: center;
  }
  .raffle-section > .ticket-display {
    width: 80%;
    margin: auto;
  }
  .raffle-section img {
    width: unset;
  }
  .prizes-section {
    flex-direction: column;
  }
  .dice-prize-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .dice-prize {
    flex-direction: column;
    width: 20%;
  }
  .dice-prize p {
    text-align: center;
  }
  .dice-roll-section {
    width: 70%;
    margin: auto;
    font-weight: bold;
    font-size: 1.5em;
  }
}
@media screen and (min-width: 1024px) {
  .current-time-section {
    padding-top: 20vw;
  }
}
@media screen and (min-width: 1200px) {
  .current-time-section {
      padding-top: 21vh;
  }
  .form-group {
    width: 20%;
  }
  .ticket-holder {
    width: 20%;
  }
  .dice-roll-section .ticket-holder{
    width: 100%;
  }
  .dice-roll-section .dice-roll img, .dice-roll-section .dice-roll p {
    width: 60%;
  }
}

/* appendix for tailwind-like nomenclature */
.bold {
  font-weight: bold;
}

.borderRadius100P {
  border-radius: 100%
}

.fontSize20 {
  font-size: 20px;
}